var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && !Array.isArray(_vm.content))?_c('div',{ref:"rootElRef",staticClass:"flexible-promo mt-xl",class:[
    _vm.content.isFullscreen ? 'full' : 'wrapper',
    { 'has-experience': Boolean(_vm.content.experience) } ],attrs:{"id":_vm.content.anchor && _vm.content.anchor.label}},[_c('div',{staticClass:"flexible-promo-inner"},[_c('div',{staticClass:"flexible-promo__panel"},[_c('div',{staticClass:"flexible-promo__panel__picto"},[_vm._v("%")]),(_vm.content.headline)?_c('div',{staticClass:"flexible-promo__panel__headline"},[_vm._v(" "+_vm._s(_vm.content.headline)+" ")]):_vm._e(),_c('div',{staticClass:"flexible-promo__panel__title"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])]),_c('div',{staticClass:"flexible-promo__picture"},[(_vm.content.experience)?_c('RouterLink',{staticClass:"flexible-promo__picture__link--card",attrs:{"to":_vm.content.experience.link.url},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}},[_c('div',{staticClass:"flexible-promo__picture__link-inner"},[_c('div',{staticClass:"flexible-promo__picture__link__label",domProps:{"innerHTML":_vm._s(_vm.content.experience.title)}}),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"flexible-promo__picture__link__icon",attrs:{"symbol":"ui-arrow-right","size":"0 0 24 25"}})])]):(_vm.content.link)?_c('GAction',{staticClass:"flexible-promo__picture__link--button",attrs:{"content":Object.assign({}, _vm.content.link,
          {label: _vm.content.link.title,
          modifiers: ['btn', 'filled'],
          icon: 'arrow'}),"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}}):_vm._e(),(_vm.content.video)?_c('UiVideoLoop',{staticClass:"flexible-promo__picture__video",attrs:{"content":{ video: _vm.content.video, picture: _vm.content.picture },"cover":true,"sizes":_vm.content.isFullscreen ? '60vw' : '(min-width: 1440px) 50vw, 60vw'}}):(
          (_vm.content.experience && _vm.content.experience.picture) ||
          _vm.content.picture
        )?_c('UiPicture',{staticClass:"flexible-promo__picture__img",attrs:{"content":_vm.content.experience ? _vm.content.experience.picture : _vm.content.picture,"cover":true,"sets":_vm.content.isFullscreen ? [] : ['320', '480', '640', '960', '1280'],"sizes":_vm.content.isFullscreen ? '60vw' : '(min-width: 1440px) 50vw, 60vw'}}):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }